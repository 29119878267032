const icons = document.querySelectorAll('.menu-icon');
const menuLinks = document.querySelector('.menu-items');
let currentEl;
let time = 2000;
let timer;

const runTimer = () => {
  return (timer = setTimeout(() => {
    if (menuLinks.classList.contains('--active')) {
      menuLinks.classList.toggle('--active');
      icons.forEach((el) => {
        menuLinks.classList.toggle('--active');
        el.classList.toggle('--active');
      });
    } else {
      return;
    }
  }, time));
};

icons.forEach((el) => {
  el.addEventListener('click', () => {
    clearTimeout(timer);
    runTimer();
    menuLinks.classList.toggle('--active');
    icons.forEach((el) => {
      menuLinks.classList.toggle('--active');
      el.classList.toggle('--active');
    });
  });
});

menuLinks.addEventListener('click', () => {
  clearTimeout(timer);
  runTimer();
});

// const icons = document.querySelectorAll('.menu_icon');
// const menuLinks = document.querySelector('.menu_items');
// const time = 2000;
// let timer;

// const toggleMenu = () => {
//   menuLinks.classList.toggle('--active');
//   icons.forEach((el) => {
//     el.classList.toggle('--active');
//   });
// };

// const runTimer = () => {
//   timer = setTimeout(() => {
//     toggleMenu();
//   }, time);
// };

// icons.forEach((el) => {
//   el.addEventListener('click', () => {
//     clearTimeout(timer);
//     toggleMenu();
//     runTimer();
//   });
// });

// menuLinks.addEventListener('click', () => {
//   clearTimeout(timer);
//   runTimer();
// });

const formBtn = document.querySelector('.form-btn-submit');
const nameInput = document.getElementById('name');
const emailInput = document.getElementById('email');
const messageInput = document.getElementById('message');
const captchaValue1 = document.querySelector('.captcha-value-1');
const captchaValue2 = document.querySelector('.captcha-value-2');
const captchaResult = document.querySelector('.captcha-result');
const modalContainer = document.querySelector('.modal_items_container');
const modal = document.querySelector('.modal');
const captchaText = document.querySelector('.captcha-text');

nameInput.setAttribute('maxlength', '30');
emailInput.setAttribute('maxlength', '70');
messageInput.setAttribute('maxlength', '500');

// captcha values
let firstValue = Math.floor(Math.random() * 10);
let secondtValue = Math.floor(Math.random() * 10);
let answer = firstValue + secondtValue;
captchaValue1.innerHTML = firstValue;
captchaValue2.innerHTML = secondtValue;

// captcha regenerator
const captchaRegenerator = () => {
  firstValue = Math.floor(Math.random() * 10);
  secondtValue = Math.floor(Math.random() * 10);
  answer = firstValue + secondtValue;
  captchaValue1.innerHTML = firstValue;
  captchaValue2.innerHTML = secondtValue;
};

// validations

// user name
const isValidUsername = (username) => {
  // Username can only contain alphanumeric characters and underscores
  // const validCharactersRegex = /^[a-zA-Z0-9_\^ç~`´¨\s]+$/;

  // Username must be between 4 and 20 characters long

  // Check if the username matches the above criteria
  // return validCharactersRegex.test(username) && username.length <= 30;
  return username.length >= 4 && username.length <= 30;
};

// email
const validateEmail = (email) => {
  // regular expression to match email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // test email against regex and return true or false
  return emailRegex.test(email) && email.length > 10 && email.length < 50;
};

//textarea
const validateMessage = (message) => {
  // Remove leading and trailing white space
  message = message.trim();

  // Check if message is at least 20 characters long and no more than 500 characters
  if (message.length < 20) {
    messageInput.value =
      'Message should be at least 20 characters long. Try again';
    return false;
  }

  // Return empty string if message is valid
  return true;
};

const formSubmit = (e) => {
  e.preventDefault();

  const userName = nameInput.value;
  const userEmail = emailInput.value;
  const userMessage = messageInput.value;

  if (!isValidUsername(userName) || userName === 'Insert a valid name') {
    nameInput.value = 'Insert a valid name';
    nameInput.focus();
    return;
  }

  if (!validateEmail(userEmail)) {
    emailInput.value = 'Inset a valid email';
    emailInput.focus();
    return;
  }

  if (
    !validateMessage(userMessage) ||
    userMessage === 'Message should be at least 20 characters long. Try again'
  ) {
    messageInput.focus();
    return;
  }

  if (!(answer === +captchaResult.value)) {
    captchaRegenerator();
    captchaResult.value = '';
    captchaText.innerHTML = 'Try again!';
    captchaResult.focus();
    return;
  }

  if (answer === +captchaResult.value) {
    fetch('https://formsubmit.co/ajax/f5bef6167546dd54abeb5bda5dac1266', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: userName,
        email: userEmail,
        message: userMessage,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === 'false') {
          modalContainer.innerHTML = `
          <i class="fa-solid fa-xmark close_icon"></i>
              <div class="error-info">
                <div class="error-text">
                  <p>Something went wrong!</p>
                  <p>For some reason, your message did not go through. Please, reach out to me using social media (Instagram, LinkedIn or facebook)</p>
                </div>
                <div class="error-icons">
                  <div class="icon">
                    <a aria-label="visit instagram" href="https://www.instagram.com/saronbrandao/" target="_blank">
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <div class="icon">
                    <a
                      aria-label="visit linkedIn" href="https://www.linkedin.com/in/saronbrandao/"
                      target="_blank"
                      ><i class="fa-brands fa-linkedin-in"></i></a>
                  </div>
                  <div class="icon">
                    <a aria-label="visit youtube" href="https://www.youtube.com/@saronbrandao" target="_blank"
                      ><i class="fa-brands fa-youtube"></i
                    ></a>
                </div>
              </div>
          `;

          const closeBtn = document.querySelector('.close_icon');
          closeBtn.addEventListener('click', () => {
            modal.classList.remove('modal--visible');
          });
          return;
        }

        if (data.success === 'true') {
          modalContainer.innerHTML = `
    <i class="fa-solid fa-xmark close_icon"></i>
        <div class="sucess-text">
          <i class="fa-regular fa-paper-plane email-sent-icon"></i>
          <p>Success!</p>
          <p>I will get back to you very soon!</p>
        </div>
    `;

          const closeBtn = document.querySelector('.close_icon');
          closeBtn.addEventListener('click', () => {
            modal.classList.remove('modal--visible');
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
    return;
  }
};

formBtn.addEventListener('click', formSubmit);

const videoContainers = document.querySelectorAll('.image-container__wrapper');
const player = document.createElement('div');
const videos = {
  iskandar: 'https://www.youtube.com/embed/SJIos6jDyjY',
  chandra: 'https://www.youtube.com/embed/_C4n4ybcsZU',
  noelia: 'https://www.youtube.com/embed/VhmQVnncP84',
  monica: 'https://www.youtube.com/embed/0phJ_1g9xGQ',
  concerts: 'https://www.youtube.com/embed/-4QCTT6RRWM',
  events: 'https://www.youtube.com/embed/C942Ynqzpvk',
  kavahu: 'https://www.youtube.com/embed/T46svlf6yzk',
  kavahuLv: 'https://www.youtube.com/embed/octBwLwzEA4',
};

videoContainers.forEach((el) => {
  el.addEventListener('click', () => {
    player.classList.add('video_loader_container');
    player.classList.add('visible');
    player.innerHTML = `<div class="video_loader_container__player-background" ></div>
    <div class="close_player_icon close_player_button">
    <div class="icon">
    <i class="fa-solid fa-xmark"></i>
    </div>
    </div>

    <div class="video_loader_container__player">
      <iframe
        class="video"
        src="${videos[el.dataset.video]}"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>`;
    document.body.appendChild(player);
    const bg = document.querySelector(
      '.video_loader_container__player-background'
    );
    const closeBtn = document.querySelector('.close_player_button');

    closeBtn.addEventListener('click', () => {
      player.remove();
    });
    bg.addEventListener('click', () => {
      player.remove();
    });
  });
});

// const videoContainers = document.querySelectorAll('.image-container__wrapper');
// const player = document.createElement('div');
// const videos = {
//   iskandar: 'https://www.youtube.com/embed/SJIos6jDyjY',
//   chandra: 'https://www.youtube.com/embed/_C4n4ybcsZU',
//   noelia: 'https://www.youtube.com/embed/VhmQVnncP84',
//   monica: 'https://www.youtube.com/embed/0phJ_1g9xGQ',
//   concerts: 'https://www.youtube.com/embed/-4QCTT6RRWM',
//   events: 'https://www.youtube.com/embed/C942Ynqzpvk',
// };

// const playerHTML = `
//   <div class="video_loader_container__player-background" ></div>
//   <div class="close_player_icon close_player_button">
//     <div class="icon">
//       <i class="fa-solid fa-xmark"></i>
//     </div>
//   </div>
//   <div class="video_loader_container__player">
//     <iframe
//       class="video"
//       src=""
//       title="YouTube video player"
//       frameborder="0"
//       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//       allowfullscreen
//     ></iframe>
//   </div>
// `;

// videoContainers.forEach((el) => {
//   el.addEventListener('click', () => {
//     player.classList.add('video_loader_container');
//     player.classList.add('visible');
//     player.innerHTML = playerHTML;
//     document.body.appendChild(player);
//     const iframe = player.querySelector('.video');
//     iframe.src = videos[el.dataset.video];
//     const bg = player.querySelector(
//       '.video_loader_container__player-background'
//     );
//     const closeBtn = player.querySelector('.close_player_button');

//     closeBtn.addEventListener('click', () => {
//       player.remove();
//     });
//     bg.addEventListener('click', () => {
//       player.remove();
//     });
//   });
// });

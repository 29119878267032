// const portImages = document.querySelectorAll('.port_img');

// const options = {
//   root: null,
//   rootMargin: '0px',
//   threshold: 0.3,
// };

// const observer = new IntersectionObserver((elements) => {
//   elements.forEach((el) => {
//     if (el.isIntersecting) {
//       if (window.screen.availWidth >= 800) {
//         el.target.src = `./assets/imgs/image_port_${el.target.dataset.image}.webp`;
//         el.target.addEventListener('load', () => {
//           el.target.classList.add('loaded');
//           el.target.classList.remove('comp');
//         });
//       } else {
//         el.target.src = `./assets/imgs/image_port_${el.target.dataset.image}_mob.webp`;
//         el.target.addEventListener('load', () => {
//           el.target.classList.add('loaded');
//           el.target.classList.remove('comp');
//         });
//       }
//     }
//     return;
//   });
// }, options);

// portImages.forEach((el) => observer.observe(el));

const portImages = document.querySelectorAll('.port_img');

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1,
};

const observer = new IntersectionObserver((elements) => {
  elements.forEach((el) => {
    if (el.isIntersecting) {
      const src = `./assets/imgs/image_port_${el.target.dataset.image}`;
      const extension = window.screen.availWidth >= 800 ? '.webp' : '_mob.webp';
      el.target.src = `${src}${extension}`;
      el.target.addEventListener('load', () => {
        el.target.classList.add('loaded');
        el.target.classList.remove('comp');
      });
    }
  });
}, options);

portImages.forEach((el) => observer.observe(el));

import ClipboardJS from 'clipboard';

const contactBtn = document.querySelectorAll('.contactBtn');
const modal = document.querySelector('.modal');
const modalBg = document.querySelector('.modal__backdrop');
const email = document.querySelector('.paragraph__text');
const paragraphIcons = document.querySelectorAll('.paragraph__icon');
const confirmationMessage = document.querySelector('.confirmation_message');
const closeBtn = document.querySelector('.close_icon');

new ClipboardJS('.paragraph__text');
new ClipboardJS('.copy_icon');

const showConfirmation = () => {
  paragraphIcons.forEach((elm) => elm.classList.toggle('--visible'));
  confirmationMessage.classList.add('--visible');
  paragraphIcons.forEach((elm) =>
    elm.removeEventListener('click', showConfirmation)
  );
  setTimeout(() => {
    paragraphIcons.forEach((elm) => elm.classList.toggle('--visible'));
    confirmationMessage.classList.remove('--visible');
    paragraphIcons.forEach((el) => {
      el.addEventListener('click', showConfirmation);
    });
  }, 3000);
};

paragraphIcons.forEach((el) => {
  el.addEventListener('click', showConfirmation);
});

contactBtn.forEach((el) => {
  el.addEventListener('click', () => {
    modal.classList.add('modal--visible');
  });
});

modalBg.addEventListener('click', () => {
  modal.classList.remove('modal--visible');
});
closeBtn.addEventListener('click', () => {
  modal.classList.remove('modal--visible');
});

email.addEventListener('click', () => {
  showConfirmation();
});

const nav = document.querySelector('.nav');
const siteLogo = document.querySelector('.logo-website');

//FUNCTIONS
const fixNav = () => {
  if (window.scrollY > nav.offsetHeight + 250) {
    nav.classList.add('active');
    siteLogo.classList.add('logo-website--dark');
  } else {
    nav.classList.remove('active');
    siteLogo.classList.remove('logo-website--dark');
  }
};

//EVENT LISTENERS
window.addEventListener('scroll', fixNav);

// const nav = document.querySelector('.nav');
// const siteLogo = document.querySelector('.logo_website');

// const toggleActiveClass = () => {
//   if (window.scrollY > nav.offsetHeight + 250) {
//     nav.classList.add('active');
//     siteLogo.classList.add('dark');
//   } else {
//     nav.classList.remove('active');
//     siteLogo.classList.remove('dark');
//   }
// };

// window.addEventListener('scroll', toggleActiveClass);
